








































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import PopoverInput from "@/components/popover-input.vue";
import {
    apiGoodsDel,
    apiGoodsRename,
    apiGoodsSort,
    apiGoodsStatus,
    apimarketindependentPricing2,
    apiGoodsDetail,
} from "@/api/goods";
import { validator } from "@/utils/util";

@Component({
    components: {
        LsDialog,
        LsPagination,
        PopoverInput,
    },
})
export default class GoodsPane extends Vue {
    $refs!: { paneTable: any };
    @Prop() value: any;
    @Prop() pager!: any;
    @Prop() extend!: any;
    @Prop({ default: true }) is_operation!: boolean;
    status = true;
    selectIds: any[] = [];
    uploadShow: Boolean = false;
    priceLoad: Boolean = false;
    loading: Boolean = false;
    tableForm: any = {
        spec_table: [],
    };
    goodsInfo: any = {
        spu: "",
        goods_id: "",
    };
    rules: any = {
        sell_price: [
            {
                required: true,
                message: "请输入商品自定义售价",
                trigger: ["blur", "change"],
            },
            {
                validator: (rule: any, value: any, callback: any) => {
                    if (value === "") {
                        callback(new Error("请输入售价"));
                    } else if (Number(value) <= 0) {
                        callback(new Error("售价不等于0!"));
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
        show_lineation_price: [
            {
                required: true,
                message: "请输入商品自定义划线价",
                trigger: ["blur", "change"],
            },
            {
                validator: (rule: any, value: any, callback: any) => {
                    if (value === "") {
                        callback(new Error("请输入划线价"));
                    } else if (Number(value) <= 0) {
                        callback(new Error("划线价不等于0!"));
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
    };

    get disabledBtn() {
        return !this.selectIds.length;
    }

    // 自主定价
    async selfPrice(row: any) {
        this.loading = true;
        let detail: any = {};
        try {
            detail = await apiGoodsDetail(row.id);
            this.loading = false;
        } catch (error) {
            this.loading = false;
        }

        this.tableForm.spec_table = detail.spec_value_list;
        this.goodsInfo.spu = row.spu;
        this.goodsInfo.goods_id = row.id;
        this.uploadShow = true;
    }

    uploadClose() {
        if (this.priceLoad) {
            return false;
        }
        this.uploadShow = false;
        this.goodsInfo = {
            spu: "",
            goods_id: "",
        };
    }
    savePrice() {
        //@ts-ignore
        const tableRef = this.$refs.tableForm as any;
        tableRef.validate((valid: boolean) => {
            if (valid) {
                this.priceLoad = true;
                let data: any = {};
                data.goods_id = this.goodsInfo.goods_id;
                data.spu = this.goodsInfo.spu;
                data.goods_list = this.tableForm.spec_table.map((v: any) => {
                    return {
                        id: v.id,
                        sell_price: v.item_pirce.sell_price,
                        show_lineation_price: v.item_pirce.show_lineation_price,
                        sku: v.sku,
                    };
                });
                apimarketindependentPricing2(data)
                    .then((res: any) => {
                        this.uploadShow = false;
                        this.priceLoad = false;
                        this.$emit("refresh");
                    })
                    .catch(() => {
                        this.priceLoad = false;
                        this.uploadShow = false;
                    });
            } else {
                return this.$message.error("请填写完整表单");
            }
        });
    }

    // 批量上架/下架商品
    handleBatchStatus({ status, ids }: any) {
        apiGoodsStatus({
            ids: ids ? ids : this.selectIds,
            status,
        }).then(() => {
            this.$emit("refresh");
        });
    }
    // 批量删除商品
    handleBatchDelete(ids: any) {
        apiGoodsDel({
            ids: Array.isArray(ids) ? ids : this.selectIds,
        }).then(() => {
            this.$emit("refresh");
        });
    }

    handleSelect(val: any[]) {
        this.selectIds = val.map((item) => item.id);
    }
    // 全选商品
    handleselectAll() {
        this.$refs.paneTable.toggleAllSelection();
    }

    handleSort(sort: string, id: number) {
        apiGoodsSort({
            id,
            sort,
        }).then(() => {
            this.$emit("refresh");
        });
    }
    handleRename(val: string, id: number) {
        apiGoodsRename({
            id,
            name: val,
        }).then(() => {
            this.$emit("refresh");
        });
    }

    ifOperation(item: any) {
        // if (!item.packge_id) return true

        // const arr: any = item.packge_id?.split(',').map(Number)

        // return !arr.includes(this.$store.state.app.config.assign_goods_packgeid)

        return !!item.is_change;
    }
}
