import request from "@/plugins/axios";

// S 商品管理
// 商品列表
export const apiGoodsAdd = (data: any) =>
    request.post("/goods.goods/add", data);

// 商品编辑
export const apiGoodsEdit = (data: any) =>
    request.post("/goods.goods/edit", data);

// 商品列表
export const apiGoodsLists = (params: any) =>
    request.get("/goods.goods/lists", { params });

// 商品列表
export const apiGoodsGoodsLists = (params: any) =>
    request.get("/goods.goods/commonLists", { params });

export const apiGoodsCommonLists = (params: any) =>
    request.get("/goods_packge.goods/commonLists", { params });

//   自主定价
export const apiindependentPricing = (data: any) =>
    request.post("/goods_packge.Goods/independentPricing", data);

//   自主定价
export const apimarketindependentPricing = (data: any) =>
    request.post("/market_goods.Goods/independentPricing", data);

//   自主定价-管理中心
export const apimarketindependentPricing2 = (data: any) =>
    request.post("/goods.Goods/independentPricing", data);

// 商品状态
export const apiGoodsStatus = (data: any) =>
    request.post("/goods.goods/status", data);

// 删除商品
export const apiGoodsDel = (data: any) =>
    request.post("/goods.goods/del", data);

// 商品排序
export const apiGoodsSort = (data: any) =>
    request.post("/goods.goods/sort", data);
// 商品排序
export const checkGoods = (data: any) =>
    request.post("/goods.goods/checkGoods", data);

// 商品详情
export const apiGoodsDetail = (id: number) =>
    request.get("/goods.goods/detail", { params: { id } });

// 商品分类/单位/供货商/品牌/运费模板列表
// 商品列表
export const apiGoodsOtherList = (params: any) =>
    request.get("/goods.goods/otherList", { params });

// 修改商品名称
export const apiGoodsRename = (data: any) =>
    request.post("goods.goods/rename ", data);

// E 商品管理

// S 品牌管理
// 新增品牌
export const apiBrandAdd = (data: any) =>
    request.post("/goods.goods_brand/add", data);

// 品牌列表
export const apiBrandLists = (params: any) =>
    request.get("/goods.goods_brand/lists", { params });

// 修改品牌状态
export const apiBrandStatus = (data: any) =>
    request.post("/goods.goods_brand/status", data);

// 删除品牌
export const apiBrandDel = (data: any) =>
    request.post("/goods.goods_brand/del", data);

// 商品详情
export const apiBrandDetail = (id: number) =>
    request.get("/goods.goods_brand/detail", { params: { id } });

// 删除品牌
export const apiBrandEdit = (data: any) =>
    request.post("/goods.goods_brand/edit", data);

// E 品牌管理

// S 供应商管理
// 供应商分类添加
export const apiSupplierCategoryAdd = (data: any) =>
    request.post("/goods.goods_supplier_category/add", data);

// 供应商分类列表
export const apiSupplierCategoryLists = (params: any) =>
    request.get("goods.goods_supplier_category/lists", { params });

// 供应商分类删除
export const apiSupplierCategoryDel = (id: number) =>
    request.post("goods.goods_supplier_category/del", { id });

// 供应商分类编辑
export const apiSupplierCategoryEdit = (data: any) =>
    request.post("goods.goods_supplier_category/edit", data);

// 添加供应商
export const apiSupplierAdd = (data: any) =>
    request.post("/goods.goods_supplier/add", data);

// 供应商列表
export const apiSupplierLists = (params: any) =>
    request.get("/goods.goods_supplier/lists", { params });

// 供应商删除
export const apiSupplierDel = (id: number) =>
    request.post("goods.goods_supplier/del", { id });

// 供应商详情
export const apiSupplierDetail = (id: number) =>
    request.get("/goods.goods_supplier/detail", { params: { id } });

// 供应商编辑
export const apiSupplierEdit = (data: any) =>
    request.post("/goods.goods_supplier/edit", data);

// E 供应商管理

// S 商品分类
// 商品分类添加
export const apiCategoryAdd = (data: any) =>
    request.post("/goods.goods_category/add", data);

// 商品分类列表
export const apiCategoryLists = (params: any) =>
    request.get("/goods.goods_category/lists", { params });

//// 商品包分类列表
export const apipackgeCategoryLists = (params: any) =>
    request.get("/goods_packge.goods_category/lists", { params });

// 商品分类列表
export const apiCategoryCommonLists = (params: any) =>
    request.get("/goods_packge.goods_category/commonLists", { params });
// // 商品分类列表
export const apiCategoryGoodsCommonLists = (params: any) =>
    request.get("/goods.goods_category/commonLists", { params });

// 修改分类状态
export const apiCategoryStatus = (data: any) =>
    request.post("/goods.goods_category/status", data);

// 分类删除
export const apiCategoryDel = (id: number) =>
    request.post("goods.goods_category/del", { id });

// 商品分类编辑
export const apiCategoryEdit = (data: any) =>
    request.post("/goods.goods_category/edit", data);

// 商品分类编辑
export const apiCategoryDetail = (id: number) =>
    request.get("/goods.goods_category/detail", { params: { id } });

// E 商品分类

// S 商品单位
// 商品单位新增
export const apiUnitAdd = (data: any) =>
    request.post("/goods.goods_unit/add", data);

// 商品单位列表
export const apiUnitLists = (params: any) =>
    request.get("/goods.goods_unit/lists", { params });

// 商品单位删除
export const apiUnitDel = (id: number) =>
    request.post("goods.goods_unit/del", { id });

// 商品单位编辑
export const apiUnitEdit = (data: any) =>
    request.post("/goods.goods_unit/edit", data);

// E 商品单位

// S 商品评价

// 商品评价列表
export const apiGoodsCommentLists = (params: any) =>
    request.get("goods.goods_comment/lists", { params });

// 商品评价删除
export const apiGoodsCommentDel = (data: any) =>
    request.post("goods.goods_comment/del", data);

// 商品评价商家回复
export const apiGoodsCommentReply = (data: any) =>
    request.post("goods.goods_comment/reply", data);

// 商品评价状态
export const apiGoodsCommentStatus = (data: any) =>
    request.post("/goods.goods_comment/status", data);

// 虚拟评价列表
export const apiGoodsCommentAssistantLists = (params: any) =>
    request.get("goods.goods_comment_assistant/lists", { params });

// 添加虚拟评价
export const apiGoodsCommentAssistantAdd = (data: any) =>
    request.post("goods.goods_comment_assistant/add", data);

// E 商品评价

// S 商品包

// 获取商品包列表
export const apiGoodsPackgeLists = (params: any) =>
    request.get("goods_packge.GoodsPackge/lists", { params });

// 获取商品包列表无分页
export const apiGoodsPackgeListsNopage = (params: any) =>
    request.get("goods_packge.GoodsPackge/listsNopage", { params });

// 新增商品包
export const apiGoodsPackgeAdd = (data: any) =>
    request.post("goods_packge.GoodsPackge/add", data);

// 编辑商品包
export const apiGoodsPackgeEdit = (data: any) =>
    request.post("goods_packge.GoodsPackge/edit", data);

// 删除商品包
export const apiGoodsPackgeDelete = (data: any) =>
    request.post("goods_packge.GoodsPackge/delete", data);

// 获取商品包信息
export const apiGoodsPackgeDetail = (params: any) =>
    request.get("goods_packge.GoodsPackge/detail", { params });

// 保存定价策略
export const savepricingStrategy = (data: any) =>
    request.post("goods_packge.GoodsPackge/platformSelfPricingStrategy", data);
// 切换商品包
export const apiGoodsPackgeSwitchStatus = (data: any) =>
    request.post("goods_packge.GoodsPackge/switchStatus", data);

// E 商品包

// S 商品包分类

// 获取列表（联合查询商品个数）
export const apiGoodsCategoryCommonLists = (params: any) =>
    request.get("goods_packge.GoodsCategory/commonLists", { params });

// 获取列表
export const apiGoodsCategoryLists = (params: any) =>
    request.get("goods_packge.GoodsCategory/lists", { params });

// 新增分类
export const apiGoodsCategoryAdd = (data: any) =>
    request.post("goods_packge.GoodsCategory/add", data);

// 删除分类

export const apiGoodsCategoryDel = (data: any) =>
    request.post("goods_packge.GoodsCategory/del", data);

// 获取分类信息
export const apiGoodsCategoryDetail = (params: any) =>
    request.get("goods_packge.GoodsCategory/detail", { params });

// 切换是否分类显示
export const apiGoodsCategoryStatus = (data: any) =>
    request.post("goods_packge.GoodsCategory/status", data);

// 编辑分类
export const apiGoodsCategoryEdit = (data: any) =>
    request.post("goods_packge.GoodsCategory/edit", data);

// 获取分类列表
export const getGoodsCategoryList = (params: any) =>
    request.get("goods.GoodsCategory/lists", { params });
// 获取商品列表
export const getGoodsList = (params: any) =>
    request.get("goods.Goods/lists", { params });
// 审核商品列表
export const getAListOfReviewedItems = (params: any) =>
    request.get("/supply_management.Goods/lists", { params });

// E 商品包分类挑选

//S 标签分组列表
//标签分组列表
export const apiSourceGoodsGroupLists = (params: any) =>
    request.get("source_goods_group.SourceGoodsGroup/lists", { params });

//标签分组列表无分页
export const apiSourceGoodsGroupListsNopage = (params: any) =>
    request.get("source_goods_group.SourceGoodsGroup/listsNopage", {
        params,
    });

//删除标签分组列表
export const apiSourceGoodsGroupDel = (id: any) =>
    request.post("source_goods_group.SourceGoodsGroup/delete", { id });
//新增分组
export const apiSourceGoodsGroupAdd = (data: any) =>
    request.post("source_goods_group.SourceGoodsGroup/add", data);
//编辑分组
export const apiSourceGoodsGroupEdit = (data: any) =>
    request.post("source_goods_group.SourceGoodsGroup/edit", data);
//详情分组
export const apiSourceGoodsGroupdDetail = (params: any) =>
    request.get("source_goods_group.SourceGoodsGroup/detail", { params });
// 获取审核分类列表
export const getCategoryList = (params: any) =>
    request.get("supply_management.PlatformGoodsCategory/lists", { params });

// 获取供应商列表
export const getBusinessList = (params: any) =>
    request.get("supply_management.Goods/businessList", { params });

//S 平台自营商品管理

//获取列表
export const getLongRangeGoodsLists = (params: any) =>
    request.get("long_range_goods.Goods/lists", { params });

//品牌列表获取
export const getLongRangeGoodsBrandLists = (params: any) =>
    request.get("long_range_goods.Goods/brandLists", { params });

//获取分类列表
export const getLongRangeGoodsCategoryLists = (params: any) =>
    request.get("long_range_goods.Goods/categoryLists", { params });

//查看详情
export const getLongRangeGoodsDetail = (params: any) =>
    request.get("long_range_goods.Goods/detail", { params });

//加入选品库
export const apiSourceGoodsAddMcsGoods = (data: any) =>
    request.post("long_range_goods.Goods/addMcsGoods", data);

//获取选品库列表
export const getLongRangeGoodsMscGoodsLists = (params: any) =>
    request.get("long_range_goods.Goods/mscGoodsLists", { params });

// 上下架
export const apiSourceGoodsEditMcsGoodsStatus = (data: any) =>
    request.post("long_range_goods.Goods/mcsGoodsStatus", data);

// 挑选
export const apiPlatformPickOut = (data: any) =>
    request.post("long_range_goods.Goods/goodsPlatformPickOut", data);
// 自有商品挑选
export const apiGoodsPickOut = (data: any) =>
    request.post("long_range_goods.Goods/goodsPickOut", data);

// 改价
export const apiSetGoodsPrice = (data: any) =>
    request.post("long_range_goods.Goods/setGoodsPrice", data);

// 改价
export const apiGetGoodsPrice = (data: any) =>
    request.post("long_range_goods.Goods/getGoodsPrice", data);

//编辑分组分类
export const apiSourceGoodsEditMcsGoods = (data: any) =>
    request.post("long_range_goods.Goods/editMcsGoods", data);

//删除选品库
export const apiSourceGoodsDeleteGoods = (data: any) =>
    request.post("long_range_goods.Goods/deleteGoods", data);

//获取在售平台字段映射

export const getLongRangeGoodsMcsSourceEnum = (params: any) =>
    request.get("long_range_goods.Goods/mcsSourceEnum", { params });
// 商品池统计

export const getgoodsPoolData = (data: any) =>
    request.post("/goods.Goods/goodsPoolData", data);

//设置定价策略
export const apiplatformSelfPricingStrategy = (data: any) =>
    request.post("/goods.goods/platformSelfPricingStrategy", data);

//获取定价策略
export const apigetPlatformSelfPricingStrategy = (data: any) =>
    request.post("/goods.goods/getPlatformSelfPricingStrategy", data);

// 选品
//选品列表goods.Goods/
export const apiGoodSelectionLists = (params: any) =>
    request.get("goods.Goods/goodsSelectionLists", { params });

//挑选商品
export const apiaddCategoryGoods = (data: any) =>
    request.post("/goods.Goods/addCategoryGoods", data);

//设置特色资源费率
export const apiSetRates = (data: any) =>
    request.post("/goods.OtherResources/setRates", data);

//设置特色资源费率
export const selectFeaturedResourcesApi = (data: any) =>
    request.post("/goods.OtherResources/selectFeaturedResources", data);

//设置特色资源费率
export const getResourcesLogApi = (params: any) =>
    request.get("/goods.OtherResources/goodsLogList", { params });

//设置特色资源费率
export const apiSetStatus = (data: any) =>
    request.post("/goods.OtherResources/setStatus", data);

//获取特色商品列表
export const apiOtherResources = (params: any) =>
    request.get("/goods.OtherResources/lists", { params });

//获取特色商品商城列表
export const otherResourcesShopListsApi = (params: any) =>
    request.get("/goods.OtherResources/otherResourcesShopLists", { params });

//获取特色商品费率设置
export const apiServiceFeeStatus = (params: any) =>
    request.get("/goods.OtherResources/serviceFeeStatus", { params });

// 分类定价列表
export const apiPlatformCategoryList = (params: any) =>
    request.get("/long_range_goods.Goods/platformCategoryList", { params });

// 分类定价
export const apiSetCategoryPrice = (data: any) =>
    request.post("/long_range_goods.Goods/setCategoryPrice", data);

// 分类定价挑选
export const apiCategoryPickOut = (data: any) =>
    request.post("/long_range_goods.Goods/categoryPickOut", data);
